import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DatePipe } from '@angular/common';
import { HttpClientJsonpModule , HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';

import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';


import { XpoApplicationSwitcherModule } from '@xpo-ltl/ngx-ltl-core/application-switcher';
import { XpoBusyLoaderModule } from '@xpo-ltl/ngx-ltl-core/busy-loader';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoCheckboxModule } from '@xpo-ltl/ngx-ltl-core/checkbox';
import { XpoChipsModule } from '@xpo-ltl/ngx-ltl-core/chips';
import { XpoClockModule } from '@xpo-ltl/ngx-ltl-core/clock';
import { XpoConfirmDialogModule } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { XpoContextualHeaderModule } from '@xpo-ltl/ngx-ltl-core/contextual-header';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoDownloadButtonModule } from '@xpo-ltl/ngx-ltl-core/download-button';
import { XpoEmptyStateModule } from '@xpo-ltl/ngx-ltl-core/empty-state';
import { XpoPageNotFoundRoutingModule } from '@xpo-ltl/ngx-ltl-core/error-page';
import { XpoErrorPagesModule } from '@xpo-ltl/ngx-ltl-core/error-pages';
import { XpoFooterModule } from '@xpo-ltl/ngx-ltl-core/footer';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoInlineSearchModule } from '@xpo-ltl/ngx-ltl-core/inline-search';
import { XpoMultiSelectModule } from '@xpo-ltl/ngx-ltl-core/multi-select';
import { XpoSelectModule } from '@xpo-ltl/ngx-ltl-core/select';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoStatusIndicatorModule } from '@xpo-ltl/ngx-ltl-core/status-indicator';
import { XpoStepperModule } from '@xpo-ltl/ngx-ltl-core/stepper';
import { XpoTabsModule } from '@xpo-ltl/ngx-ltl-core/tabs';
import { XpoTileRibbonModule } from '@xpo-ltl/ngx-ltl-core/tile-ribbon';
import { XpoSlideToggleModule } from '@xpo-ltl/ngx-ltl-core/toggle';
import { XpoTooltipModule } from '@xpo-ltl/ngx-ltl-core/tooltip';
import { XpoTrayModule } from '@xpo-ltl/ngx-ltl-core/tray';
import { XpoUploadModule } from '@xpo-ltl/ngx-ltl-core/upload';
import { XpoLtlPopoverModule } from '@xpo-ltl/ngx-ltl-core/xpo-ltl-popover';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-ltl-auth/ui';
import { XpoLtlAuthFirestoreModule, XPO_LTL_AUTH_FIRESTORE_CONFIG, XpoLtlAuthFirestoreConfig } from '@xpo-ltl/ngx-ltl-auth/firestore';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule, ConfigManagerService } from '@xpo-ltl/config-manager';
import { AgGridModule } from 'ag-grid-angular';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ag-grid';

import './_reusable/extensions/array.extension';
import './_reusable/extensions/string.extension'; 
import './_reusable/extensions/object.extension';

import { TooltipListPipe } from './_reusable/pipes/tooltipListPipe';


import { SdkDynamicPricingModule } from '@xpo-ltl/sdk-dynamicpricing';
import { SdkPricingModule } from '@xpo-ltl/sdk-pricing';

import { LandingPageComponent } from './screens/landing-page/landing-page.component';
import { RfpEditComponent } from './screens/rfp-edit/rfp-edit.component';
import { CustomerInfoComponent } from './screens/rfp-edit/components/customer-info/customer-info.component';
import { RfpGeneralComponent } from './screens/rfp-edit/components/forms/rfp-general/rfp-general.component';
import { RfpTariffDiscountComponent } from './screens/rfp-edit/components/forms/rfp-tariff-discount/rfp-tariff-discount.component';
import { RfpAccessorialsComponent } from './screens/rfp-edit/components/forms/rfp-accessorials/rfp-accessorials.component';
import { RfpFakOverridesComponent } from './screens/rfp-edit/components/forms/rfp-fak-overrides/rfp-fak-overrides.component';
import { RfpDocumentsComponent } from './screens/rfp-edit/components/forms/rfp-documents/rfp-documents.component';
import { RfpMessagesComponent } from './screens/rfp-edit/components/forms/rfp-messages/rfp-messages.component';
import { BooleanCellRendererComponent } from './_reusable/cell-renderers/boolean-cell-renderer/boolean-cell-renderer.component';
import { CurrencyCellRendererComponent } from './_reusable/cell-renderers/currency-cell-renderer/currency-cell-renderer.component';
import { StatusCellRendererComponent } from './_reusable/cell-renderers/status-cell-renderer/status-cell-renderer.component';
import { PresetNumberRangeFilterComponent } from './_reusable/grid-filters/preset-number-range-filter/preset-number-range-filter.component';
import { IconButtonCellRendererComponent } from './_reusable/cell-renderers/icon-button-cell-renderer/icon-button-cell-renderer.component';
import { ShipmentSummaryCellRendererComponent } from './_reusable/cell-renderers/shipment-summary-cell-renderer/shipment-summary-cell-renderer.component';
import { CheckboxCellRendererComponent } from './_reusable/cell-renderers/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { DownloadCellRendererComponent } from './_reusable/cell-renderers/download-cell-renderer/download-cell-renderer.component';
import { UploadDocumentsDialogComponent } from './screens/rfp-edit/components/forms/rfp-documents/upload-documents-dialog/upload-documents-dialog.component';
import { MessageComponent } from './screens/rfp-edit/components/forms/rfp-messages/rfp-message/rfp-message.component';
import { MessageEditDialogComponent } from './screens/rfp-edit/components/forms/rfp-messages/message-edit-dialog/message-edit-dialog.component';
import { RfpLocationsComponent } from './screens/rfp-edit/components/forms/rfp-locations/rfp-locations.component';
import { VarDirective } from './_directives/ng-var.directive';
import { RfpContractedComponent } from './screens/rfp-edit/rfp-contracted/rfp-contracted.component';
import { RfpDynamicComponent } from './screens/rfp-edit/rfp-dynamic/rfp-dynamic.component';
import { RfpHistoryComponent } from './screens/rfp-edit/components/forms/rfp-history/rfp-history.component';
import { RfpDptComponent } from './screens/rfp-edit/components/forms/rfp-dpt/rfp-dpt.component';
import { CustomerInfoDialogComponent } from './screens/rfp-edit/components/dialogs/customer-info-dialog/customer-info-dialog.component';
import { SnackBarErrorListComponent } from './_reusable/snackbars/snack-bar-error-list/snack-bar-error-list.component';
import { RfpCreateDialogComponent } from './_reusable/dialogs/rfp-create-dialog/rfp-create-dialog.component';
import { SubscriptionDialogComponent } from './screens/rfp-edit/components/dialogs/subscription-dialog/subscription-dialog.component';
import { RfpCancelPricingComponent } from './screens/rfp-edit/rfp-cancel-pricing/rfp-cancel-pricing.component';
import { MyRfpsComponent } from './screens/my-rfps/my-rfps.component';
import { UserSelectDialogComponent } from './_reusable/dialogs/user-select-dialog/user-select-dialog.component';
import { SubmitToPricingDialogComponent } from './screens/rfp-edit/components/dialogs/submit-to-pricing-dialog/submit-to-pricing-dialog.component';
import { AccountHierarchyComponent } from './_reusable/account-hierarchy/account-hierarchy.component';
import { AccountFunctionDisplayPipe } from './_reusable/account-hierarchy/account-function-display.pipe';
import { RfpPremiumServicesComponent } from './screens/rfp-edit/components/forms/rfp-premium-services/rfp-premium-services.component';
import { AdminDashboardComponent } from './screens/admin-dashboard/admin-dashboard.component';
import { ManagerDashboardComponent } from './screens/manager-dashboard/manager-dashboard.component';
import { UserEditDialogComponent } from './screens/manager-dashboard/user-edit-dialog/user-edit-dialog.component';
import { AdminLookupsComponent } from './screens/admin-dashboard/admin-lookups/admin-lookups.component';
import { NewLookupDialogComponent } from './screens/admin-dashboard/admin-lookups/new-lookup-dialog/new-lookup-dialog.component';
import { XpoGridToolbarModule } from './_reusable/ngx-grid-copy/projected-features/toolbar';
import { UserBulkEditDialogComponent } from './screens/manager-dashboard/user-bulk-edit-dialog/user-bulk-edit-dialog.component';
import { AdminPsaeAssignmentComponent } from './screens/admin-dashboard/admin-psae-assignment/admin-psae-assignment.component';
import { PsaeAssignmentAddDialogComponent } from './screens/admin-dashboard/admin-psae-assignment/psae-assignment-add-dialog/psae-assignment-add-dialog.component';
import { RfpMabdComponent } from './screens/rfp-edit/components/forms/rfp-premium-services/rfp-mabd/rfp-mabd.component';
import { RfpMexicoComponent } from './screens/rfp-edit/components/forms/rfp-premium-services/rfp-mexico/rfp-mexico.component';
import { UserNotFoundComponent } from './screens/user-not-found/user-not-found.component';
import { RfpRootComponent } from './screens/rfp-root/rfp-root.component';
import { XpoStatusIndicatorCellRendererComponent } from './_reusable/cell-renderers/xpo-status-indicator-cell-renderer/xpo-status-indicator-cell-renderer.component';
import { AdminRfpConfigComponent } from './screens/admin-dashboard/admin-rfp-config/admin-rfp-config.component';

export function authConfigFactory(configManagerService: ConfigManagerService): XpoLtlAuthFirestoreConfig {
  return new XpoLtlAuthFirestoreConfig(
    {
      appCode: configManagerService.getSetting('authAppCode'),
      scopes: configManagerService.getSetting('authScopes'),
      isProd: configManagerService.getSetting('authIsProd'),
      applicationRootUri: configManagerService.getSetting('authAppRootUri'),
    },
    {
      appName: configManagerService.getSetting('appName'),
      apiUri: configManagerService.getSetting('apiUrl'),
      scopeOptions: configManagerService.getSetting('scopeOptions'),
      authGrantType: configManagerService.getSetting('authGrantType'),
    },
    {
      firebaseOptions: environment.firebase,
      region: configManagerService.getSetting('region'),
    }
  );
}

@NgModule({
  declarations: [
    AppComponent,
    BooleanCellRendererComponent,
    CurrencyCellRendererComponent,
    StatusCellRendererComponent,
    LandingPageComponent,
    MyRfpsComponent,
    RfpEditComponent,
    CustomerInfoComponent,
    RfpGeneralComponent,
    RfpTariffDiscountComponent,
    RfpAccessorialsComponent,
    RfpFakOverridesComponent,
    RfpDocumentsComponent,
    RfpMessagesComponent,
    PresetNumberRangeFilterComponent,
    IconButtonCellRendererComponent,
    ShipmentSummaryCellRendererComponent,
    CheckboxCellRendererComponent,
    DownloadCellRendererComponent,
    UploadDocumentsDialogComponent,
    MessageComponent,
    MessageEditDialogComponent,
    RfpLocationsComponent,
    VarDirective,
    RfpContractedComponent,
    RfpDynamicComponent,
    RfpHistoryComponent,
    RfpDptComponent,
    CustomerInfoDialogComponent,
    SnackBarErrorListComponent,
    RfpCreateDialogComponent,
    SubscriptionDialogComponent,
    RfpCancelPricingComponent,
    UserSelectDialogComponent,
    SubmitToPricingDialogComponent,
    AccountHierarchyComponent,
    AccountFunctionDisplayPipe,
    RfpPremiumServicesComponent,
    AdminDashboardComponent,
    ManagerDashboardComponent,
    UserEditDialogComponent,
    AdminLookupsComponent,
    NewLookupDialogComponent,
    UserBulkEditDialogComponent,
    AdminPsaeAssignmentComponent,
    XpoStatusIndicatorCellRendererComponent,

    TooltipListPipe,
    PsaeAssignmentAddDialogComponent,
    RfpMabdComponent,
    RfpMexicoComponent,
    UserNotFoundComponent,
    RfpRootComponent,
    AdminRfpConfigComponent,

  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AgGridModule.withComponents([PresetNumberRangeFilterComponent]),
    
    BrowserModule,
    BrowserAnimationsModule,
    ConfigManagerModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    FormsModule,

    XpoAgGridModule,
    XpoApplicationSwitcherModule,
    XpoBusyLoaderModule,
    XpoButtonModule,
    XpoCardModule,
    XpoCheckboxModule,
    XpoChipsModule,
    XpoClockModule,
    XpoConfirmDialogModule,
    XpoContextualHeaderModule,
    XpoDialogModule,
    XpoDownloadButtonModule,
    XpoEmptyStateModule,
    XpoErrorPagesModule,
    XpoFooterModule,
    XpoGridToolbarModule,
    XpoIconModule,
    XpoInlineSearchModule,
    XpoLtlAuthUiModule,
    XpoLtlPopoverModule,
    XpoMultiSelectModule,
    XpoSelectModule,
    XpoShellModule,
    XpoSlideToggleModule,
    XpoSnackBarModule,
    XpoStatusIndicatorModule,
    XpoStepperModule,
    XpoTabsModule,
    XpoTileRibbonModule,
    XpoTooltipModule,
    XpoTrayModule,
    XpoUploadModule,

    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTooltipModule,

    SdkDynamicPricingModule,
    SdkPricingModule,

    // For SSO
    XpoLtlAuthFirestoreModule.forRoot({
      provide: XPO_LTL_AUTH_FIRESTORE_CONFIG,
      useFactory: authConfigFactory,
      deps: [ConfigManagerService],
    }),
    AppRoutingModule,
    XpoPageNotFoundRoutingModule
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
