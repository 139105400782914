import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { ApplicationOptions, RfpMgmtApiService, RfpUser, UpsertUserRqst, UserRoleOptions } from '@xpo-ltl/sdk-rfpmgmt';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { UserService } from 'src/app/_services/user.service';
import { RfpConfigService, RfpMgmtConfig } from 'src/app/_services/rfp.config.service';

export interface UserEditDialogInput {
  selectedUser: RfpUser;
  allUsers: RfpUser[],
}

enum UserEditFormFields {
  OneCrmOverride = "oneCrmOverride",
  Manager = 'manager',
  RegionCode = 'regionCode',
  RoleType = 'roleType',
  ChannelCode = 'channelCode',
  EffectiveDate = 'effectiveDate',
  ExpiryDate = 'expiryDate',

  IsAdmin = 'isAdmin',
  IsManager = 'isManager',
  IsRds = 'isRds',
  ManageUserRdsReview = 'manageUserRdsReview',

  EnablePremiumServices = 'enablePremiumServices',
  RequireRdsReview = 'requireRdsReview',
  CannotModifyRfp = 'cannotModifyRfp',
  CreateRfp = 'createRfp'
}

@Component({
  selector: 'user-edit-dialog',
  templateUrl: './user-edit-dialog.component.html',
  styleUrls: ['./user-edit-dialog.component.scss']
})
export class UserEditDialogComponent implements OnInit {

  selectedUser: RfpUser;
  allUsers: RfpUser[];
  filteredManagerOptions$: Observable<RfpUser[]>;
  userEditForm: UntypedFormGroup;
  currentUserIsAdmin: boolean = false;
  currentUserIsRdsManager: boolean = false;

  userEditFormFields = UserEditFormFields;

  oneCrmFields: UserEditFormFields[] = [
    UserEditFormFields.Manager,
    UserEditFormFields.RegionCode,
    UserEditFormFields.RoleType,
    UserEditFormFields.ChannelCode,
    UserEditFormFields.EffectiveDate,
    UserEditFormFields.ExpiryDate,
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UserEditDialogInput,
    public dialogRef: MatDialogRef<UserEditDialogComponent, RfpUser>,
    private formBuilder: UntypedFormBuilder,
    private rfpApi: RfpMgmtApiService,
    private snackbar: XpoSnackBar,
    public userService: UserService,
    private rfpConfigService: RfpConfigService,
  ) {
    this.allUsers = data.allUsers;
    this.selectedUser = data.selectedUser;
    this.userService.rfpUser$.subscribe({
      next: (user: RfpUser) => {
        this.currentUserIsAdmin = user.roleOptions.isAdmin;
        this.currentUserIsRdsManager = user.roleOptions.manageUserRdsReview;
      }
    });
  }

  ngOnInit(): void {
    this.createForm()
  }

  createForm(): void {
    this.userEditForm = this.formBuilder.group({
      [UserEditFormFields.OneCrmOverride]:  [false],
      [UserEditFormFields.Manager]:         [this.allUsers.find(e => e.employeeId == this.selectedUser.managerEmployeeId), Validators.required],
      [UserEditFormFields.RegionCode]:      [this.selectedUser?.regionCode || ''],
      [UserEditFormFields.RoleType]:        [this.selectedUser?.roleType || ''],
      [UserEditFormFields.ChannelCode]:     [this.selectedUser?.channelCode || ''],
      [UserEditFormFields.EffectiveDate]:   [this.selectedUser?.effectiveDate || ''],
      [UserEditFormFields.ExpiryDate]:      [this.selectedUser?.expiryDate || ''],

      [UserEditFormFields.IsAdmin]:             [this.selectedUser?.roleOptions?.isAdmin || false],
      [UserEditFormFields.IsManager]:           [this.selectedUser?.roleOptions?.isManager || false],
      [UserEditFormFields.IsRds]:               [this.selectedUser?.roleOptions?.isRds || false],
      [UserEditFormFields.ManageUserRdsReview]: [this.selectedUser?.roleOptions?.manageUserRdsReview || false],

      [UserEditFormFields.EnablePremiumServices]:        [this.selectedUser?.applicationOptions?.enablePremiumServices || false],
      [UserEditFormFields.RequireRdsReview]:             [this.selectedUser?.applicationOptions?.requireRdsReview || false],
      [UserEditFormFields.CannotModifyRfp]:              [this.selectedUser?.applicationOptions?.cannotModifyRfp || false],
      [UserEditFormFields.CreateRfp]:                    [this.selectedUser?.applicationOptions?.createRfp || false],
    });

    this.enableOneCrmFields(false);

    this.userEditForm.get(UserEditFormFields.OneCrmOverride).valueChanges.subscribe((x: boolean) => this.enableOneCrmFields(x))

    this.filteredManagerOptions$ = this.userEditForm.get(UserEditFormFields.Manager)?.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      map(value => {
        if(typeof value === "string" || value instanceof String)
          return this.allUsers.filter(x => x.employeeFullName.toLowerCase().includes(value.toLowerCase()))
        else return [value]
      })
    )
  }

  enableOneCrmFields(enable: boolean) {
    this.oneCrmFields.forEach(field => {
      if(enable) this.userEditForm.get(field).enable()
      else this.userEditForm.get(field).disable()
    })
  }

  userFieldDisplay(user: RfpUser) {
    return user ? `${user.employeeId} - ${user.employeeFullName.toTitleCase()}` : ""
  }

  submit(){
    const formValue = this.userEditForm.value

    const applicationOptions: Partial<ApplicationOptions> = {
      enablePremiumServices: formValue[UserEditFormFields.EnablePremiumServices],
      requireRdsReview:      formValue[UserEditFormFields.RequireRdsReview],
      cannotModifyRfp:       formValue[UserEditFormFields.CannotModifyRfp],
      createRfp:             formValue[UserEditFormFields.CreateRfp],
    }
    const userRoleOptions: Partial<UserRoleOptions> = {
      isAdmin:              formValue[UserEditFormFields.IsAdmin],
      isManager:            formValue[UserEditFormFields.IsManager],
      isRds:                formValue[UserEditFormFields.IsRds],
      manageUserRdsReview:  formValue[UserEditFormFields.ManageUserRdsReview],
    }


    const updatedUser: Partial<UpsertUserRqst> = {
      employeeId:         this.selectedUser.employeeId,
      managerEmployeeId:  formValue[UserEditFormFields.Manager]       ? formValue[UserEditFormFields.Manager]?.employeeId : this.selectedUser.managerEmployeeId,
      regionCode:         formValue[UserEditFormFields.RegionCode]    ? formValue[UserEditFormFields.RegionCode] : this.selectedUser.regionCode,
      roleType:           formValue[UserEditFormFields.RoleType]      ? formValue[UserEditFormFields.RoleType] : this.selectedUser.roleType,
      channelCode:        formValue[UserEditFormFields.ChannelCode]   ? formValue[UserEditFormFields.ChannelCode] : this.selectedUser.channelCode,
      effectiveDate:      formValue[UserEditFormFields.EffectiveDate] ? new Date(formValue[UserEditFormFields.EffectiveDate]).toISOString() : this.selectedUser.effectiveDate,
      expiryDate:         formValue[UserEditFormFields.ExpiryDate]    ? new Date(formValue[UserEditFormFields.ExpiryDate]).toISOString() : this.selectedUser.expiryDate,
      applicationOptions: applicationOptions as ApplicationOptions,
      userRoleOptions:    userRoleOptions as UserRoleOptions,
      source:             this.rfpConfigService.getConfigValue(RfpMgmtConfig.OriginAppSource, 'RFP-SALES')
    };

    this.rfpApi.upsertUser(updatedUser as UpsertUserRqst).subscribe(
      response => {
        this.snackbar.success(`${response.employeeFullName} Successfully Updated!`)
        this.dialogRef.close(response)
      },
      error => {
        this.snackbar.error(`There was an issue updating this user.`)
      }
    )

  }
}