import { Injectable } from '@angular/core';
import { RfpFieldData, RfpMgmtApiService } from '@xpo-ltl/sdk-rfpmgmt';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RfpConfigService {
  private _configFields: { [key: string]: any } = {}; // Store config as key-value pairs

  constructor(private rfpApi: RfpMgmtApiService) {}

  public loadConfig(): Observable<void> {
    return this.rfpApi.getRfpFieldData().pipe(
      map((resp) => {
        resp.items.forEach((field) => {
          this._configFields[field.fieldName] = this.convertFieldType(field);
        });
      })
    );
  }

  private convertFieldType(field: RfpFieldData): any {
    // Convert strings to appropriate types based on field name or value
    if (field.value === 'true') return true;
    if (field.value === 'false') return false;
    if (!isNaN(Number(field.value))) return Number(field.value);
    return field.value; // Return string as-is for non-boolean, non-number values
  }

  public getConfigValue(key: RfpMgmtConfig, fallback: any = null) {
    return this._configFields[key] ?? fallback;
  }
}


export enum RfpMgmtConfig {
  CorpCode = 'CorpCode',
  DocClass = 'DocClass',
  EnablePsaeChange = 'EnablePsaeChange',
  ShowCreateRfp = 'ShowCreateRfp',
  EnableSellerChange = 'EnableSellerChange',
  RdsUserRoleType = 'RdsUserRoleType',
  EnableRdsChange = 'EnableRdsChange',
  PsaeUserRoleType = 'PsaeUserRoleType',
  EnableReturnReasons = 'EnableReturnReasons',
  EnableDptLocations = 'EnableDptLocations',
  EnablePremiumServicesReview = 'EnablePremiumServicesReview',
  EnableTdcSunset = 'EnableTdcSunset',
  EnableRdsReviewUser = 'EnableRdsReviewUser',
  EnablePremiumServices = 'EnablePremiumServices',
  NationalSellerUserRoleType = 'NationalSellerUserRoleType',
  NatChAccBusinessDays = 'NatChAccBusinessDays',
  NatCanPrcBusinessDays = 'NatCanPrcBusinessDays',
  NatNewOppBusinessDays = 'NatNewOppBusinessDays',
  NatRenoBusinessDays = 'NatRenoBusinessDays',
  LocalAddlBusinessDays = 'LocalAddlBusinessDays',
  LocalNewOppBusinessDays = 'LocalNewOppBusinessDays',
  LocalRenoBusinessDays = 'LocalRenoBusinessDays',
  MonitorNAdjustDuplicateAlertEnabled = 'MonitorNAdjustDuplicateAlertEnabled',
  AutoCloseDynamicRfpJobSendEmail = 'AutoCloseDynamicRfpJobSendEmail',
  LocalChAccBusinessDays = 'LocalChAccBusinessDays',
  RecipientsAnalystType = 'RecipientsAnalystType',
  UseAppsettingsRecipients = 'UseAppsettingsRecipients',
  PricingEscalationAlertEnabled = 'PricingEscalationAlertEnabled',
  RfpStatusChangeAlertEnabled = 'RfpStatusChangeAlertEnabled',
  RdsReviewAlertEnabled = 'RdsReviewAlertEnabled',
  NewMessagesAlertEnabled = 'NewMessagesAlertEnabled',
  DisableDynamicPricingNotifications = 'DisableDynamicPricingNotifications',
  LocalCanPrcBusinessDays = 'LocalCanPrcBusinessDays',
  Recipients = 'Recipients',
  NatAddlBusinessDays = 'NatAddlBusinessDays',
  LettersBusinessDays = 'LettersBusinessDays',
  MonitorNAdjustAlertEnabled = 'MonitorNAdjustAlertEnabled',
  InCycleRfpAutoPushPricingDays = 'InCycleRfpAutoPushPricingDays',
  AutoCloseInCycleRfpsJobSendEmail = 'AutoCloseInCycleRfpsJobSendEmail',
  RunAutoCloseDynamicRfpJob = 'RunAutoCloseDynamicRfpJob',
  RunSynchronizeRfpUserJob = 'RunSynchronizeRfpUserJob',
  RunInCycleRfpAutoPushPricingJob = 'RunInCycleRfpAutoPushPricingJob',
  RunMigrateCurrentEscalationsJob = 'RunMigrateCurrentEscalationsJob',
  RunMonitorPendingRfpRdsReviewJob = 'RunMonitorPendingRfpRdsReviewJob',
  CanCopyRfpDetailsToProposal = 'CanCopyRfpDetailsToProposal',
  RdsReviewTitles = 'RdsReviewTitles',
  DptThreshold = 'DptThreshold',
  EnableRetailRollout = 'EnableRetailRollout',
  RdsReviewThresholdHours = 'RdsReviewThresholdHours',
  PrcCommentMaxSize = 'PrcCommentMaxSize',
  MaxFileNameLength = 'MaxFileNameLength',
  DisableDuplicateRfp = 'DisableDuplicateRfp',
  ResetEscalationReviews = 'ResetEscalationReviews',
  InCycleAutoPricingTypes = 'InCycleAutoPricingTypes',
  DefaultBusinessDays = 'DefaultBusinessDays',
  EnableRdsReview = 'EnableRdsReview',
  LocalSellerUserRoleType = 'LocalSellerUserRoleType',
  MatrixDiscountIncludeTariff = 'MatrixDiscountIncludeTariff',
  UseNewDmsCorpCode = 'UseNewDmsCorpCode',
  RunAutoCloseInCycleRfpJob = 'RunAutoCloseInCycleRfpJob',
  DynamicRfpAutoCloseDays = 'DynamicRfpAutoCloseDays',
  TestMadCodes = 'TestMadCodes',
  EscalationMessageAlertEnabled = 'EscalationMessageAlertEnabled',
  OriginAppSource = 'OriginAppSource',
}
